/** @format */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Cabin", sans-serif;
}
/////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR //////////////////////////
body {
  width: 100% !important;
}
body::-webkit-scrollbar-track {
  background: #f7fbff !important;
}
body::-webkit-scrollbar-thumb {
  background: #c51559 !important;
  border-radius: 16px;
}
body::-webkit-scrollbar {
  width: 3px;
}
body {
  background: url("https://imgur.com/UYPpes4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background-color: #c51559 !important;
  padding: 10px !important;
}

.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}
.mybtn {
  background: #2a3427 !important;
  border: 0;
  color: #f8faf8 !important;
  font-size: 20px !important;
}
/////////////////////////// HEADER SECTION ////////////////////////////////////////
.Header-Section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .navbar-brand {
    font-family: "Cabin", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  ul li a {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Cabin", sans-serif;
    // text-transform: uppercase;
    font-size: 1rem;
    color: #ffffff !important;
    padding: 0.5rem 0.75rem;
  }

  .navbar-item {
    position: relative;

    border: 0;
    font-family: "Cabin", sans-serif;
    // text-transform: uppercase;
    color: #ffffff;
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    min-width: 130px;
    font-size: 1rem;
    height: 40px;
    border-color: #dbdbdb;
    border-width: 1px;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#eb689a),
      to(#c51559)
    ) !important;
    background: linear-gradient(to top, #eb689a, #c51559) !important;
    opacity: 1;
    z-index: 0;
    margin-left: 20px;
    justify-content: center;

    &:hover {
      box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
      opacity: 0.95;
    }
  }
}
.claim{
  position: relative;

  border: 0;
  font-family: "Cabin", sans-serif;
  // text-transform: uppercase;
  color: #ffffff;
  -webkit-transform: skewX(-18deg);
  transform: skewX(-18deg);
  min-width: 130px;
  font-size: 1rem;
  height: 40px;
  border-color: #dbdbdb;
  border-width: 1px;
  align-items: center;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.6s ease-out !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#eb689a),
    to(#c51559)
  ) !important;
  background: linear-gradient(to top, #eb689a, #c51559) !important;
  opacity: 1;
  z-index: 0;

  justify-content: center;

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
    opacity: 0.95;
  }

}

@media only screen and (max-width: 600px) {
  .navbar-collapse {
    margin-top: 20px;
    background: rgb(18, 21, 17);
    padding: 20px;

    .navbar-item {
      margin-top: 20px;
    }
  }
}

//////////////////////////// HERO SECTION ///////////////////////////////////

.Hero-Selection {
  .Content-Selection {
    text-align: center;
    color: #fff !important;
    font-family: "Cabin", sans-serif;

    h1 {
      font-size: 1.75rem;
      margin-bottom: 16px;
    }

    p {
      font-size: 1.3rem;
      margin-bottom: 50px;
    }

    .box-bg {
      backdrop-filter: blur(10px) !important;
      background: #c5155949;
      border-radius: 16px;
      color: white;
      border: 1px dashed #c51559;
      padding: 30px 20px;
      min-height: 340px;

      .css-1hv8oq8-MuiStepLabel-label {
        color: #ffffff !important;
        text-align: center;
        font-size: 16px !important;
      }
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
        color: #888c87 !important;
      }
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: #e7145b !important;
      }

      .stepper-1 {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

/////////////////////////////////////  FOOterSECTION  /////////////////////

.Footer-Section {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;

  ul li {
    list-style: none;
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
}
